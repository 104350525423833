import React, { useEffect } from "react";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function Policy() {
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="row pt-5">
          <div className="col-md-12 pt-5">
            <p
              className="text-start pb-4"
              style={{
                fontSize: "2em",
                fontFamily: "Noto Sans",
                fontWeight: 600,
                color: "#fff",
              }}
            >
              PRIVACY POLICY
            </p>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1.6em", fontWeight: "bold", color: "#fff" }}
            >
              🛡️ Introduction
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              Welcome to <strong>Concept Tek Solution</strong>—where your
              privacy is our priority. We are dedicated to protecting the
              personal information of our Customers, Partners, Employees, and
              anyone interacting with us (referred to as "User" or "You"). Our
              commitment extends to safeguarding your data whether it's at-rest,
              in-use, or in-motion—ensuring your business runs smoothly and
              securely.
            </p>

            <p
              className="text-start pb-2"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              🔍 Our Privacy Principles:
            </p>
            <ul
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>
                  Minimal Data Collection:
                </span>{" "}
                We gather only what is essential to serve you better.
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Secure Data Sharing:</span>{" "}
                Controlled, authorized access to your information.
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Advanced Security:</span>{" "}
                State-of-the-art technology to protect your data.
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Purpose-Driven:</span> We
                collect data only for specific, clear objectives.
              </li>
            </ul>
            <p
              className="text-start pb-3 pt-1"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              This Privacy Policy outlines how we collect, use, disclose, and
              protect your information across our websites, platforms, offline
              services, and more (collectively, the "Services").
            </p>

            <p
              className="text-start pb-2"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              📑 What You'll Find Here:
            </p>
            <ul
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">Definitions</li>
              <li className="text-start">Data Collection</li>
              <li className="text-start">How We Use Your Data</li>
              <li className="text-start">When We Share Information</li>
              <li className="text-start">Data Security</li>
              <li className="text-start">Your Rights & Choices</li>
              <li className="text-start">General Information</li>
              <li className="text-start">Policy Updates</li>
              <li className="text-start">Contacting Us</li>
            </ul>
            <p
              className="text-start pb-3 pt-1"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              By using our platform or services, you agree to the collection,
              storage, and processing of your data as described in this policy.
              If you provide us with third-party data, you confirm you have the
              authority to do so. Your personal data will primarily be stored
              and processed in
              <span style={{ fontWeight: "600" }}> India.</span>
            </p>
<hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              📖 Definitions
            </p>
            <ol
              type="1"
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                paddingLeft: "50px",
                listStyleType: "decimal",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                <strong>Customer/User/You:</strong> Any individual, business, or
                entity engaging with Concept Tek Solution.
              </li>
              <li className="text-start">
                <strong>Website(s):</strong>
                <br />
                <ul
                  style={{
                    fontSize: "1em",
                    color: "#B2BABB",
                    listStyleType: "disc",
                    paddingLeft: "30px",
                    lineHeight: 2,
                  }}
                >
                  <li>
                    Our official sites, including{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/")}
                    >
                      conceptteksolution.com
                    </span>
                  </li>
                  <li>Any linked pages referring to this Privacy Policy.</li>
                </ul>
              </li>
              <li className="text-start">
                <strong>Concept/We/Us:</strong> Concept Tek Solution and its
                affiliates, subsidiaries, and associates.
              </li>
              <li className="text-start">
                <strong>Concept Partners:</strong> Authorized businesses that
                sell our products and services.
              </li>
              <li className="text-start">
                <strong>Personal Data:</strong> Any non-anonymized information
                you provide.
              </li>
              <li className="text-start">
                <strong>Sensitive Personal Data:</strong> Special categories
                such as passwords, financial details, health data, and biometric
                data.
              </li>
            </ol>
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 pt-4"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              📋 Data Collection
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We collect data to provide and improve our services. While you can
              browse anonymously, sharing your information enables us to
              personalize your experience.
            </p>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1.2em", fontWeight: "600", color: "#fff" }}
            >
              📌 What We Collect:
            </p>
            <ul
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Personal Info:</span> Name,
                contact details, address, etc.
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Transaction Data:</span>{" "}
                Records of your interactions with us.
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Correspondence:</span>{" "}
                Emails, messages, or letters you send us.
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Cookies & Tech Data:</span>{" "}
                IP addresses, browser details, device type, and activity on our
                platform.
              </li>
            </ul>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1.2em", fontWeight: "600", color: "#fff" }}
            >
              🛠️ How We Collect It:
            </p>
            <ul
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                Online forms, phone, SMS, chat, and mail.
              </li>
              <li className="text-start">
                Events, trade shows, and marketing campaigns.
              </li>
              <li className="text-start">
                Product license activation and usage.
              </li>
              <li className="text-start">
                Third-party platforms—governed by their privacy policies.
              </li>
            </ul>

            {/* How We Use Data Section */}
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 pt-4"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              🎯 How We Use Your Data
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We use your data to offer a personalized, seamless experience
              while ensuring compliance with legal obligations.
            </p>
            <ol
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                paddingLeft: "50px",
                listStyleType: "decimal",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                <strong>Marketing & Communication:</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                    paddingLeft: "30px",
                    lineHeight: 2,
                  }}
                >
                  <li>Send you updates, offers, and event invitations</li>
                </ul>
              </li>
              <li className="text-start">
                <strong>Customer Support:</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                    paddingLeft: "30px",
                    lineHeight: 2,
                  }}
                >
                  <li>Identify and assist you across our contact points</li>
                </ul>
              </li>
              <li className="text-start">
                <strong>Compliance & Security:</strong>
                <ul
                  style={{
                    listStyleType: "circle",
                    paddingLeft: "30px",
                    lineHeight: 2,
                  }}
                >
                  <li>Detect, prevent, and address illegal activities</li>
                </ul>
              </li>
            </ol>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB", fontStyle: "italic" }}
            >
              💡 <b>Note:</b> We will <b>never</b> share your sensitive data
              with third parties for their marketing without your explicit
              consent.
            </p>

            {/* Data Sharing Section */}
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 pt-4"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              🔗 When We Share Information
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We share your information only when necessary and under strict
              confidentiality.
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We may share your data with:
            </p>
            <ul
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>
                  Affiliates & Subsidiaries:
                </span>
                For seamless service delivery
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Service Providers:</span>{" "}
                Including payment processors and communication agents
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Authorized Partners:</span>{" "}
                To fulfill service requests
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Legal Entities:</span> For
                compliance with law enforcement and regulatory bodies
              </li>
            </ul>
            <p
              className="text-start pb-3 pt-2"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We may share anonymized data for research or analytical
              purposes—but <b>never</b> your identifiable information without
              your permission.
            </p>
            {/* Data Security Section */}
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 pt-4"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              🔒 Data Security
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We take your data security seriously and use advanced measures to
              protect it.
            </p>
            <ul
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Secure Servers:</span> Your
                data is stored on protected systems
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Access Control:</span>{" "}
                Restricted to authorized personnel only
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Data Transmission:</span>{" "}
                While we strive for complete security, no online system is
                entirely risk-free
              </li>
            </ul>

            {/* User Rights Section */}
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 pt-4"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              ⚖️ Your Rights & Choices
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              You have control over how your data is used.
            </p>
            <ul
              style={{
                fontSize: "1em",
                color: "#B2BABB",
                listStyleType: "disc",
                paddingLeft: "50px",
                lineHeight: 2.5,
              }}
            >
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>
                  Access & Confirmation:
                </span>
                Know what data we store and how we use it
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Correction:</span> Update or
                correct your information
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Data Portability:</span>{" "}
                Request a copy of your data in portable format
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Erasure:</span> Ask us to
                delete your data (with legal exceptions)
              </li>
              <li className="text-start">
                <span style={{ fontWeight: "600" }}>Withdraw Consent:</span> You
                can withdraw consent anytime—may limit services
              </li>
            </ul>

            <p
              className="text-start pb-3 pt-2"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              📩 <b>Need help?</b> Contact us at conceptteksolution@gmail.com.
            </p>
            {/* General Information Section */}
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 pt-4"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              📌 General Information
            </p>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1.2em", fontWeight: "600", color: "#fff" }}
            >
              📝 Consent:
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              By using our services, you agree to our Privacy Policy. Withdrawal
              of consent may limit your access to certain features.
            </p>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1.2em", fontWeight: "600", color: "#fff" }}
            >
              📄 Data Retention:
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We retain your data only as long as necessary for business, legal,
              and compliance purposes. Anonymized data may be retained
              indefinitely for research.
            </p>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1.2em", fontWeight: "600", color: "#fff" }}
            >
              📲 Platform Usage:
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              Our services are for users <b>18 years and older</b>. If you share
              a minor’s data, you confirm you have authority to do so.
            </p>

            {/* Policy Updates Section */}
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 pt-4"
              style={{ fontSize: "1.5em", fontWeight: "bold", color: "#fff" }}
            >
              🔔 Policy Updates
            </p>
            <p
              className="text-start pb-3"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              We update this Privacy Policy as needed to reflect changes in our
              practices. Significant updates will be communicated via our
              platform or email.
            </p>
            <p
              className="text-start"
              style={{ fontSize: "1em", color: "#B2BABB", fontStyle: "italic" }}
            >
              Last Updated: [28.02.2025]
            </p>
            <hr className="my-3" style={{border:"1.5px solid white"}}/>
            <p
              className="text-start pb-2 mt-6"
              style={{ fontSize: "2em", fontWeight: "bold", color: "#fff" }}
            >
              📬 Contact Us
            </p>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1em", color: "#B2BABB" }}
            >
              For questions or concerns, reach out to our{" "}
              <b>Grievance Redressal Office:</b>
            </p>
            <p
              className="text-start pb-2"
              style={{ fontSize: "1em", color: "#fff" }}
            >
              <b>Name</b>: Avinash Jain <br />
              <b>Designation</b>: Proprietor <br />
              <b>Email</b>: conceptteksolution@gmail.com
            </p>
          </div>
        </div>
      </div>
      <button
        className="scroll-to-top-button border-0 rounded-2"
        onClick={scrollToTop}
        style={{
          width: 40,
          height: 40,
          backgroundColor: "#ff68f0",
          color: "#fff",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <IoArrowUpCircleOutline style={{ fontSize: 40 }} />
      </button>
    </div>
  );
}
